"use strict";

define(['R', 'jquery', 'noty'],
    /**
     * @param {Root} R
     * @param $
     * @param noty
     * @returns {mApply}
     */
    function (R, $, noty)
{
    class mApply extends R.core.Module {
        constructor() {
            super();
        }

        init(el) {
            if (!super.init()) {
                this.$mod = $(el);
                R.regMod(this);
                window.mApply = this;

                this.$modalApply = $('#m-ApplyModal');
                R.appendModal(this.$modalApply);

                this.apply_id = this.$mod.attr('data-apply_id') || 0;

                this.$formApply = $('form', this.$modalApply);

                class mApplyForm extends R.core.Form {
                    constructor(mod, form, rules) {
                        super(form, rules);

                        this.mod = mod;
                    }

                    _submitForm(opts) {
                        this.$submit_btn.addClass('loading');
                        super._submitForm(opts);
                    }

                    ajaxComplete() {
                        this.$submit_btn.removeClass('loading');
                    }

                    ajaxError() {
                    }

                    ajaxSuccess(data) {
                        if (!data.error) {
                            this.mod.apply_id = data.apply_id;
                            this.mod.$modalApply.modal('hide');
                            this.mod.$modalSms.modal('show');
                        }
                        // switch (data) {
                        //     case 0:
                        //         break;
                        //     case 1:
                        //         break;
                        //     case 2:
                        //         $('.m-ApplyModal-error2').fadeIn(0);
                        //         break;
                        //     case 3:
                        //         $('.m-ApplyModal-error3').fadeIn(0);
                        //         break;
                        //     case 4:
                        //         $('.m-ApplyModal-error4').fadeIn(0);
                        //         break;
                        //     case 7:
                        //         $('.m-ApplyModal-error7').fadeIn(0);
                        //         break;
                        // }
                    }

                }

                this.formObj = new mApplyForm(
                    this,
                    this.$formApply,
                    {
                        fullname   : ['required', {min_length: 6}],
                        email      : ['required', 'email', {min_length: 6}],
                        webpage    : ['required', 'url', {min_length: 8}],
                        title_id   : ['required'],
                        mobile     : ['required', 'positive_integer', {min_length: 8}],
                        mobilecode : ['required', {like: ['^[+,0-9]+$']}, {min_length: 1}]
                    }
                );


                //
                this.$modalSms = $('#m-ApplySmsModal');
                R.appendModal(this.$modalSms);

                var ThankNotice = $('.m-ApplySmsModal-thanknotice', this.$modalSms).html();

                this.$formSms = $('form', this.$modalSms);
                this.$modalSmsResend = $('.m-ApplySmsModal-resend', this.$formSms);

                class mSmsVerForm extends R.core.Form {
                    constructor(form, rules, mod) {
                        super(form, rules);
                        this.mod = mod;
                    }

                    _submitForm(opts) {
                        this.$submit_btn.addClass('loading');
                        super._submitForm(opts);
                    }

                    ajaxComplete() {
                        this.$submit_btn.removeClass('loading');
                    }

                    ajaxError() {
                    }

                    ajaxSuccess(data) {
                        if (!data.error) {
                            $(".modal-body", this.mod.$modalSms).html(ThankNotice);
                        } else {
                            if (data.error == 'wrong_code') {
                                this.validFail({
                                    code : {
                                        code:'WRONG_VALUE'
                                    }
                                });
                            } else {
                                noty({
                                    type : 'error',
                                    layout: 'bottomRight',
                                    text : R.text('Error')
                                });
                            }
                        }
                    }
                }

                this.formObj = new mSmsVerForm(
                    this.$formSms,
                    {
                        apply_id : ['required', 'integer'],
                        code: ['required']
                    },
                    this
                );
                this.setListeners();
            }

            this.start();
        }

        setListeners() {
            this.$mod
                .on('click', (e)=>{
                    e.stopPropagation();
                    e.preventDefault();

                    if (this.apply_id) {
                        this.$modalSms.modal('show');
                    } else {
                        this.$modalApply.modal('show');
                    }
                })
            ;
            //apply

            //sms
            this.$modalSms
                .on('show.bs.modal', ()=>{
                    if (this.apply_id) $('input[name="apply_id"]').val(this.apply_id);
                })
            ;

            this.$modalSmsResendTimer = $('.m-ApplySmsModal-resendTimer', this.$modalSms);
            this.$modalSmsResendError = $('.m-ApplySmsModal-resendError', this.$modalSms);

            this.$modalSms
                .on('click', '.m-ApplySmsModal-resend', (e)=>{
                    e.stopPropagation();
                    e.preventDefault();

                    let $el = $(e.currentTarget);

                    if ($el.hasClass('loading')) return;

                    $el.addClass('loading');

                    R.ajax({
                        url     : 'mod/apply/resendSms',
                        success : (data)=>{
                            if (data.error) {
                                switch (data.error) {
                                    case 'sent_recently':
                                        let seconds = 60;
                                        if (data.sec) seconds = data.sec;
                                        this.setSmsTimer(seconds);
                                        break;
                                    default:
                                        noty({
                                            type : 'error',
                                            layout: 'bottomRight',
                                            text : R.text('Error')
                                        });
                                        break;
                                }
                            } else {
                                noty({
                                    type : 'success',
                                    layout: 'bottomRight',
                                    text : R.text('SMS sent')
                                });
                            }
                        },
                        complete : ()=>{
                            $el.removeClass('loading');
                        }
                    });
                })
            ;
        }

        setSmsTimer(sec=0) {
            sec = sec|0;
            if (sec < 0) sec = 0;

            this.smsTimerSec = sec;
            this.$modalSmsResendTimer.html(this.smsTimerSec);

            if (sec) {
                this.$modalSmsResend.addClass('disabled');
                this.$modalSmsResendError.show();

                if (this.smsTimer == undefined) {
                    this.smsTimer = setInterval(()=>{
                        this.setSmsTimer(this.smsTimerSec-1);
                    }, 1000);
                }
            } else {
                this.$modalSmsResend.removeClass('disabled');
                this.$modalSmsResendError.hide();

                if (this.smsTimer != undefined) {
                    clearInterval(this.smsTimer);
                    this.smsTimer = undefined;
                }
            }
        }

        start() {
            super.start();
            this.play()
        }

        play() {
            super.play();
        }
    }

    R.regModCls(mApply);

    define("m-apply", [], function() {return mApply;});

    return mApply;
});
